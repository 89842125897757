import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import moment from 'moment';
import {
    agregarInfo, statusData,
    validarCampo, validarValoresFormulario, evento, statusError,
    getNextStepIdentification
} from '../../services/data'
import Loader from '../../components/loader'
import { isIOS } from 'react-device-detect'
import Footer from '../../components/footer'
import CancelarFlujo from '../../components/cancelar_flujo'
import { hayExcepcion, generateZip, validarRedireccionamiento } from "../../components/captura_identificacion/services/data";

let FormularioEstudiante = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [esIne, setEsIne] = useState(true);
    const [esCLaboral, setCLaboral] = useState(true);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({
        full_name: '',
        id: '',
        academic_program: '',
        unit: '',
        title: '',
        first_names: '',
        last_names: '',
        position: '',
        secondment: '',
        curp: '',
        documentoNacionalMexico: '',
        // expireDate: '',
        side: '',
        type: '',
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [esVigente, setEsVigente] = useState(true)
    const [fechaNacimiento, setfechaNacimiento] = useState("");
    const [sendForm, setSendForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showFooter, setShowFooter] = useState(true)
    const [showCancelScreen, setShowCancelScreen] = useState(false)
    const [uuidTransaccion, setUuidTransaccion] = useState('')

    // Habilitar campos del formulario por default
    const [enableFullName, setEnableFullName] = useState(true)
    const [enableId, setEnableId] = useState(true)
    const [enableAcademicProgram, setEnableAcademicProgram] = useState(false)
    const [enableUnit, setEnableUnit] = useState(true)
    const [enableTitle, setEnableTitle] = useState(false)
    const [enableFirstNames, setEnableFirstNames] = useState(false)
    const [enableLastNames, setEnableLastNames] = useState(false)
    const [enablePosition, setEnablePosition] = useState(false)
    const [enableSecondment, setEnableSecondment] = useState(false)
    const [enableCurp, setEnableCurp] = useState(true)
    const [enableDocumentoNacionalMexico, setEnableDocumentoNacionalMexico] = useState(false)
    const [enableExpireDate, setEnableExpireDate] = useState(true)
    const [enableSide, setEnableSide] = useState(false)
    const [enableType, setEnableType] = useState(false)

    const [databack, setDataback] = useState(false)

    const [focusFullName, setFocusFullName] = useState(false)
    const [focusAcademicProgram, setFocusAcademicProgram] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false);
    const [focusUnit, setFocusUnit] = useState(false);
    const [focusId, setFocusId] = useState(false)
    const [focusExpireDate, setFocusExpireDate] = useState(false);
    const [focusTitle, setFocusTitle] = useState(false)
    const [focusFirstNames , setFocusFirstNames] = useState(false)
    const [focusLastNames, setFocusLastNames] = useState(false)
    const [focusPosition, setFocusPosition] = useState(false)
    const [focusSecondment, setFocusSecondment] = useState(false)
    const [focusSide, setFocusSide] = useState(false)
    const [focusType, setFocusType] = useState(false)
    const [focusDocumentoNacionalMexico, setFocusDocumentoNacionalMexico] = useState(false)

    const [forVigencia, setForVigencia] = useState("");





    useEffect(() => {
        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }

        let uuidTransaccionLS = localStorage.getItem("uuidTrx")
        if (uuidTransaccionLS) {
            setUuidTransaccion(uuidTransaccionLS)
        }

        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            setDataOCRFront(ocrFront);
            if (ocrFront.full_name){
                setInputsValue(inputsValue => ({ ...inputsValue, ["full_name"]: ocrFront.full_name || '' }));
                if (ocrFront.full_name === "Unavailable") {
                    setInputsValue(inputsValue => ({ ...inputsValue, ["full_name"]: '' }));
                }
            }
                
            if (ocrFront.id) {
                setInputsValue(inputsValue => ({ ...inputsValue, ["id"]: ocrFront.id || '' }));
                if (ocrFront.id === "Unavailable") {
                    setInputsValue(inputsValue => ({ ...inputsValue, ["id"]: 'N/A' }));
                }
            }
    
            if (ocrFront.academic_program)
                setInputsValue(inputsValue => ({ ...inputsValue, ["academic_program"]: ocrFront.academic_program || '' }));
                
            if (ocrFront.unit){
                setInputsValue(inputsValue => ({ ...inputsValue, ["unit"]: ocrFront.unit || '' }));
                if (ocrFront.id === "Unavailable") {
                    setInputsValue(inputsValue => ({ ...inputsValue, ["unit"]: 'N/A' }));
                }
            }
            
            if (ocrFront.title)
                setInputsValue(inputsValue => ({ ...inputsValue, ["title"]: ocrFront.title || '' }));
            
            if (ocrFront.first_names)
                setInputsValue(inputsValue => ({ ...inputsValue, ["first_names"]: ocrFront.first_names || '' }));
                
            if (ocrFront.last_names)
                setInputsValue(inputsValue => ({ ...inputsValue, ["last_names"]: ocrFront.last_names || '' }));

            if (ocrFront.position)
                setInputsValue(inputsValue => ({ ...inputsValue, ["position"]: ocrFront.position || '' }));

            if (ocrFront.secondment)
                setInputsValue(inputsValue => ({ ...inputsValue, ["secondment"]: ocrFront.secondment || '' }));

            if (ocrFront.side)
                setInputsValue(inputsValue => ({ ...inputsValue, ["side"]: ocrFront.side || '' }));

            if (ocrFront.type)
                setInputsValue(inputsValue => ({ ...inputsValue, ["type"]: ocrFront.type || '' }));

            if (ocrFront.type === "officer") {
                setEnableFullName(true)
                setEnableId(false)
                setEnableAcademicProgram(false)
                setEnableUnit(false)
                setEnableTitle(false)
                setEnableFirstNames(false)
                setEnableLastNames(false)
                setEnablePosition(true)
                setEnableSecondment(false)
                setEnableSide(false)
                setEnableType(false)
            }
            else if (ocrFront.type === "student")  {
                setEnableFullName(true)
                setEnableId(true)
                setEnableAcademicProgram(true)
                setEnableUnit(true)
                setEnableTitle(false)
                setEnableFirstNames(false)
                setEnableLastNames(false)
                setEnablePosition(false)
                setEnableSecondment(false)
                setEnableSide(false)
                setEnableType(false)
            }
            else if (ocrFront.type === "teacher")  {
                setEnableFullName(true)
                setEnableId(true)
                setEnableAcademicProgram(false)
                setEnableUnit(false)
                setEnableTitle(false)
                setEnableFirstNames(false)
                setEnableLastNames(false)
                setEnablePosition(true)
                setEnableSecondment(false)
                setEnableSide(false)
                setEnableType(false)
            }
        }

        if (localStorage.getItem("apikeyOtorgante") === "50e6a1b5-fc07-459d-a78a-de4a3cad810b") {
            setCLaboral(false)
        }

        let dataOCRBackLS = localStorage.getItem("ocrBack");
        if (dataOCRBackLS != null) {
            const ocrBack = JSON.parse(dataOCRBackLS)
            if (ocrBack.curp)
                if (ocrBack.curp.length > 14){
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrBack.curp).trim() }));
                }
                if (ocrBack.curp === "Unavailable") {
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: '' }));
                }
            // setInputsValue(inputsValue => ({ ...inputsValue, ['documentoNacionalMexico']: ocrBack.documentoNacionalMexico || '' }));
            // setInputsValue(inputsValue => ({ ...inputsValue, ['side']: ocrBack.side || '' }));
            // setInputsValue(inputsValue => ({ ...inputsValue, ['type']: ocrBack.type || '' }));

            let vigenciaC = '';
            if (ocrBack.expireDate) {
                if (ocrFront.type !== "officer") {
                    vigenciaC = ocrBack.expireDate;
                    let formatosP = ['DD/MM/YYYY', 'DD/MM/YY'];
                    if (moment(vigenciaC, formatosP, true).isValid()) {
                        setInputsValue(inputsValue => ({ ...inputsValue, ["expireDateFull"]: vigenciaC }));
                        setForVigencia("expireDateFull")
                    }
                    // setInputsValue(inputsValue => ({ ...inputsValue, ["expireDate"]: "20" + vigenciaC.substr(-2) }));
                } else {
                    setInputsValue(inputsValue => ({ ...inputsValue, ["expireDate"]: vigenciaC }));
                    setForVigencia("expireDate")
                }
            }

            setDataback(true)
            if (ocrBack.type === "officer") {
                setEnableCurp(true)
                setEnableDocumentoNacionalMexico(false)
                setEnableExpireDate(true)
                setEnableSide(false)
                setEnableType(false)
            }
            else if (ocrBack.type === "student")  {
                setEnableCurp(true)
                setEnableDocumentoNacionalMexico(false)
                setEnableExpireDate(true)
                setEnableSide(false)
                setEnableType(false)
            }
            else if (ocrBack.type === "teacher")  {
                setEnableCurp(true)
                setEnableDocumentoNacionalMexico(false)
                setEnableExpireDate(true)
                setEnableSide(false)
                setEnableType(false)
            }
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let validacion = null;
        let value = event.target.value.trim().toUpperCase();
        if (event.target.name === 'curp') {
            let maxLength = 18;
            if (event.target.value.length > maxLength) {
                value = value.substr(0, maxLength);
                event.target.value = value;
            }
            validacion = validarCampo(value, event.target.name);
        } else {
            validacion = validarCampo(value, event.target.name);
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront");
        if (dataOCRFrontLS != null) {
            const ocrFront = JSON.parse(dataOCRFrontLS)

            let tipoIdentificacion = ocrFront.type === 'officer' ? true : false
            if (tipoIdentificacion === true) {
                setForVigencia("expireDate")
            } else {
                setForVigencia("expireDateFull")
            }
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }

    };

    useEffect(() => {
        // console.log("inputsValueM", inputsValue);
    }, [inputsValue]);

    const validarFormulario = (event) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        setSendForm(true);

        let continuar = validarValoresFormulario(inputsValue);

        if (continuar.correcto) {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'OK' }, true);

            let infoFormulario = []
            let fechaActual = inputsValue.expireDate ? inputsValue.expireDate : inputsValue.expireDateFull
            
            agregarInfo(dataUser, { description: "nombreCompleto", value: inputsValue.full_name })
            infoFormulario.push({ description: "nombreCompleto", value: inputsValue.full_name })

            agregarInfo(dataUser, { description: "id", value: inputsValue.id })
            infoFormulario.push({ description: "id", value: inputsValue.id })

            agregarInfo(dataUser, { description: "prorgramaAcademico", value: inputsValue.academic_program })
            infoFormulario.push({ description: "prorgramaAcademico", value: inputsValue.academic_program })

            agregarInfo(dataUser, { description: "Unidad", value: inputsValue.unit })
            infoFormulario.push({ description: "Unidad", value: inputsValue.unit })

            agregarInfo(dataUser, { description: "titulo", value: inputsValue.title })
            infoFormulario.push({ description: "titulo", value: inputsValue.title })

            agregarInfo(dataUser, { description: "first_names", value: inputsValue.first_names })
            infoFormulario.push({ description: "first_names", value: inputsValue.first_names })

            agregarInfo(dataUser, { description: "last_names", value: inputsValue.last_names })
            infoFormulario.push({ description: "last_names", value: inputsValue.last_names })

            agregarInfo(dataUser, { description: "position", value: inputsValue.position })
            infoFormulario.push({ description: "position", value: inputsValue.position })

            agregarInfo(dataUser, { description: "secondment", value: inputsValue.secondment })
            infoFormulario.push({ description: "secondment", value: inputsValue.secondment })

            agregarInfo(dataUser, { description: "side", value: inputsValue.side })
            infoFormulario.push({ description: "side", value: inputsValue.side })

            agregarInfo(dataUser, { description: "type", value: inputsValue.type })
            infoFormulario.push({ description: "type", value: inputsValue.type })
            
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "curp", value: inputsValue.curp });

            agregarInfo(dataUser, { description: "documentoNacionalMexico", value: inputsValue.documentoNacionalMexico });
            infoFormulario.push({ description: "documentoNacionalMexico", value: inputsValue.documentoNacionalMexico });

            if (inputsValue.type === 'officer') {
                agregarInfo(dataUser, { description: "expireDate", value: inputsValue.expireDate });
                infoFormulario.push({ description: "expireDate", value: inputsValue.expireDate });
            } else {
                agregarInfo(dataUser, { description: "expireDateFull", value: inputsValue.expireDateFull });
                infoFormulario.push({ description: "expireDateFull", value: inputsValue.expireDateFull });
            }

            let today = new Date();
            
            if (inputsValue.type === 'officer') {
                let year = today.getFullYear();
                if (Number(fechaActual) >= year) {
                    infoFormulario.push({ description: "expireDate", value: "31/12/20" + inputsValue.expireDate.substr(-2) })
                    agregarInfo(dataUser, { description: "expireDate", value: "31/12/20" + inputsValue.expireDate.substr(-2) });
                    fechaActual = "20" + inputsValue.expireDate.substr(-2) + "/12/31";
                    let fechaV = new Date(fechaActual);
                    if (fechaV >= Date.now()) {
                        sendData(infoFormulario)
                        setTimeout(() => {
                            if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_2', "true") === "true") {
                                history.push('/clausula');
                            } else if (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_3', "true") === "true") {
                                history.push("/autorizacion_solicitud");
                            } else {
                                let nextStep = getNextStepIdentification(dataOtorgante)
                                history.push(nextStep);
                            }
                        }, 200);
                    } else {
                        console.log("Vigencia invalida");
                        setEsVigente(false)
                    }
                } else {
                    console.log("Vigencia invalida");
                    setEsVigente(false)
                }
            } else {
                let dateParts = fechaActual.split("/")
                let fechaV = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
                if (fechaV >= Date.now()) {
                    setTimeout(() => {
                        sendData(infoFormulario)
                        setLoading(false)
                        if (hayExcepcion()) {
                            history.push('/clausula')
                        } else {
                            let nextStep = getNextStepIdentification(dataOtorgante)
                            history.push(nextStep);
                        }
                    }, 500)
                } else {
                    setLoading(false)
                    setEsVigente(false)
                }
            }        
        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false);
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }));
            setSendForm(false);
        }
    }

    const statusSE = () => {
        evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true);
    }

    const terminarFlujo = async (event) => {
        if (event) event.preventDefault()
        let data = 'Cancelado'
        setLoading(true)
        const responseZip = await generateZip('TRUNCOS', 'trunco', isIOS)
        await statusError("datos_personales_ocr", data, "cancelado")
        evento('Datos personales OCR', 'Click', { description: 'TERMINAR', status: data }, true)
        if (!window.opener) {
            if (validarRedireccionamiento(dataOtorgante)) {
                setTimeout(() => {
                    history.push("/" + apiKey)
                setLoading(false)
                }, 300);
            } else {
                setShowCancelScreen(true)
                setLoading(false)
            }
        }
    }

    const handleKeyUp = (event) => event.target.value = event.target.value.toUpperCase()

    return (
        <>
            <form id="FormularioEstudiante">
                <div className="ocr_data_display animate__animated">
                    { (enableFullName) ? 
                        <div className={["form-group", "bmd-form-group", ((focusFullName || inputsValue.full_name !== "") ? "is-focused" : ""), inputsErrors.full_name && 'error'].join(" ")}>
                            <label htmlFor="full_name" className="bmd-label-floating">Nombre completo:*</label>
                            <textarea type="text" className="form-control" id="full_name" name="full_name" defaultValue={inputsValue.full_name} onChange={handleChange} onFocus={e => {
                                setFocusFullName(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.full_name === "") {
                                    setFocusFullName(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.full_name && (
                                <span id="ht-full_name" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.full_name}</span>
                            )}
                        </div> : ''
                    }

                    { (enableCurp) ?
                        <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp) ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                            <label htmlFor="curp" className="bmd-label-floating">CURP:*</label>
                            <input type="text" className="form-control" id="curp" name="curp" defaultValue={inputsValue.curp} onChange={handleChange} onFocus={e => {
                                setFocusCurp(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.curp === '') {
                                    setFocusCurp(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.curp && (
                                <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                            )}
                        </div>: ''
                    }

                    { (enableAcademicProgram ) ?
                        <div className={["form-group", "bmd-form-group", ((focusAcademicProgram || inputsValue.academic_program !== "") ? "is-focused" : ""), inputsErrors.academic_program && 'error'].join(" ")}>
                            <label htmlFor="academicProgram" className="bmd-label-floating">Programa académico:*</label>
                            <input type="text" className="form-control" id="academicProgram" name="academicProgram" defaultValue={inputsValue.academic_program} onChange={handleChange} onFocus={e => {
                                setFocusAcademicProgram(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.academic_program === "") {
                                    setFocusAcademicProgram(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.full_name && (
                                <span id="ht-full_name" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.academic_program}</span>
                            )}
                        </div> : ''
                    }

                    { (enablePosition) ?
                        <div className={["form-group", "bmd-form-group", ((focusPosition || inputsValue.position !== "") ? "is-focused" : ""), inputsErrors.position && 'error'].join(" ")}>
                            <label htmlFor="position" className="bmd-label-floating">Área de adscripción:</label>
                            <input type="text" className="form-control" id="position" name="position" defaultValue={inputsValue.position} onChange={handleChange} onFocus={e => {
                                // setTituloInstitucion(true)
                                setFocusPosition(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.position === "") {
                                    // setTituloInstitucion(false)
                                    setFocusPosition(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.position && (
                                <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.position}</span>
                            )}
                        </div> : ''
                    }

                    { (enableUnit ) ?
                        <div className={["form-group", "bmd-form-group", ((focusUnit || inputsValue.unit !== "") ? "is-focused" : ""), inputsErrors.unit && 'error'].join(" ")}>
                            <label htmlFor="unit" className="bmd-label-floating">Unidad académica:*</label>
                            <input type="text" className="form-control" id="unit" name="unit" defaultValue={inputsValue.unit} onChange={handleChange} onFocus={e => {
                                setFocusUnit(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.unit === "") {
                                    setFocusUnit(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.unit && (
                                <span id="ht-unit" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.unit}</span>
                            )}
                        </div> : ''
                    }

                        <div className="row_ext">
                            <div className="row">
                            { (enableId) ?
                                <div className="col-6">
                                    <div className={["form-group", "bmd-form-group", ((focusId || inputsValue.id !== "") ? "is-focused" : ""), inputsErrors.id && 'error'].join(" ")}>
                                        <label htmlFor="id" className="bmd-label-floating">Boleta:*</label>
                                        <input type="number" className="form-control" id="id" name="id" defaultValue={inputsValue.id} onChange={handleChange} onFocus={e => {
                                            setFocusId(true)
                                            setInputActive(true)
                                            setShowFooter(false)
                                        }} onBlur={e => {
                                            setShowFooter(true)
                                            if (inputsValue.id === "") {
                                                setFocusId(false)
                                            }
                                            setInputActive(false)
                                        }} />
                                        {inputsErrors.id && (
                                            <span id="ht-id" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.id}</span>
                                        )}
                                    </div>
                                </div> : ''
                            }

                            { (enableExpireDate) ?
                                <div className={(enableId === false) ? 'col-12': 'col-6'}>
                                    <div className={["form-group", "bmd-form-group", ((focusExpireDate || inputsValue.expireDate || inputsValue.expireDateFull ) ? "is-focused" : "" ), (inputsErrors.expireDate || inputsErrors.expireDateFull) && 'error'].join(" ")}>
                                        {/* <label htmlFor={( inputsValue.expireDate ? 'expireDate' : 'expireDateFull' )} className="bmd-label-floating">Vigencia:</label> */}
                                        <label htmlFor={forVigencia} className="bmd-label-floating">Vigencia:*</label>
                                        {/* <input type="text" className="form-control" id={( inputsValue.expireDate ? 'expireDate' : 'expireDateFull' )} name={( inputsValue.expireDate ? 'expireDate' : 'expireDateFull' )} defaultValue={inputsValue.expireDate ? inputsValue.expireDate : inputsValue.expireDateFull } onChange={handleChange} onFocus={e => { */}
                                        <input type="text" className="form-control" id={forVigencia} name={forVigencia} defaultValue={inputsValue.expireDate ? inputsValue.expireDate : inputsValue.expireDateFull } onChange={handleChange} onFocus={e => {
                                            setFocusExpireDate(true)
                                            setInputActive(true)
                                            setShowFooter(false)
                                        }} onBlur={e => {
                                            setShowFooter(true)
                                            if ((inputsValue.expireDate || inputsValue.expireDateFull )=== '') {
                                                setFocusExpireDate(false)
                                            }
                                            setInputActive(false)
                                        }} />
                                        {(inputsErrors.expireDate || inputsErrors.expireDateFull) && (
                                            <span id="ht-expireDate" className="helper-text ht" data-error="wrong" data-success="right">{(inputsErrors.expireDate || inputsErrors.expireDateFull)}</span>
                                        )}
                                    </div>
                                </div> : ''
                                }
                            </div>
                        </div>
                    
                    { (enableTitle ) ?
                        <div className={["form-group", "bmd-form-group", ((focusTitle|| inputsValue.title !== "") ? "is-focused" : ""), inputsErrors.title && 'error'].join(" ")}>
                            <label htmlFor="tituloInstitucion" className="bmd-label-floating">Título:</label>
                            <input type="text" className="form-control" id="tituloInstitucion" name="tituloInstitucion" defaultValue={inputsValue.title} onChange={handleChange} onFocus={e => {
                                // setTituloInstitucion(true)
                                setFocusTitle(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.title === "") {
                                    // setTituloInstitucion(false)
                                    setFocusTitle(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.title && (
                                <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.title}</span>
                            )}
                        </div> : ''
                    }

                    { (enableFirstNames) ?
                        <div className={["form-group", "bmd-form-group", ((focusFirstNames || inputsValue.first_names !== "") ? "is-focused" : ""), inputsErrors.first_names && 'error'].join(" ")}>
                            <label htmlFor="firstNames" className="bmd-label-floating">Nombres:</label>
                            <input type="text" className="form-control" id="firstNames" name="firstNames" defaultValue={inputsValue.first_names} onChange={handleChange} onFocus={e => {
                                // setTituloInstitucion(true)
                                setFocusFirstNames (true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.first_names === "") {
                                    // setTituloInstitucion(false)
                                    setFocusFirstNames (false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.first_names && (
                                <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.first_names}</span>
                            )}
                        </div> : ''
                    }

                    { (enableLastNames) ?
                        <div className={["form-group", "bmd-form-group", ((focusLastNames || inputsValue.last_names !== "") ? "is-focused" : ""), inputsErrors.last_names && 'error'].join(" ")}>
                            <label htmlFor="lastNames" className="bmd-label-floating">Apellidos:</label>
                            <input type="text" className="form-control" id="lastNames" name="lastNames" defaultValue={inputsValue.last_names} onChange={handleChange} onFocus={e => {
                                // setLastNames(true)
                                setFocusLastNames(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.last_names === "") {
                                    // setLastNames(false)
                                    setFocusLastNames(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.last_names && (
                                <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.last_names}</span>
                            )}
                        </div> : ''
                    }

                    { (enableSecondment) ?
                        <div className={["form-group", "bmd-form-group", ((focusSecondment|| inputsValue.secondment !== "") ? "is-focused" : ""), inputsErrors.secondment && 'error'].join(" ")}>
                            <label htmlFor="secondment" className="bmd-label-floating">Adscripción:</label>
                            <input type="text" className="form-control" id="secondment" name="secondment" defaultValue={inputsValue.secondment} onChange={handleChange} onFocus={e => {
                                // setTituloInstitucion(true)
                                setFocusSecondment(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.secondment === "") {
                                    // setTituloInstitucion(false)
                                    setFocusSecondment(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.secondment && (
                                <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.secondment}</span>
                            )}
                        </div> : ''
                    }

                    { (enableSide) ?
                        <div className={["form-group", "bmd-form-group", ((focusSide || inputsValue.side !== "") ? "is-focused" : ""), inputsErrors.side && 'error'].join(" ")}>
                            <label htmlFor="side" className="bmd-label-floating">Lado:</label>
                            <input type="text" className="form-control" id="side" name="side" defaultValue={inputsValue.side} onChange={handleChange} onFocus={e => {
                                // setTituloInstitucion(true)
                                setFocusSide(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.side === "") {
                                    // setTituloInstitucion(false)
                                    setFocusSide(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.side && (
                                <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.side}</span>
                            )}
                        </div> : ''
                    }

                    { (enableType) ?
                        <div className={["form-group", "bmd-form-group", ((focusType || inputsValue.type !== "") ? "is-focused" : ""), inputsErrors.type && 'error'].join(" ")}>
                            <label htmlFor="typeTitulo" className="bmd-label-floating">Tipo:</label>
                            <input type="text" className="form-control" id="typeTitulo" name="typeTitulo" defaultValue={inputsValue.type} onChange={handleChange} onFocus={e => {
                                // setTituloInstitucion(true)
                                setFocusType(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.type === "") {
                                    // setTituloInstitucion(false)
                                    setFocusType(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.type && (
                                <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.type}</span>
                            )}
                        </div> : ''
                    }

                    { (enableDocumentoNacionalMexico) ?
                        <div className={["form-group", "bmd-form-group", ((focusDocumentoNacionalMexico || inputsValue.documentoNacionalMexico) ? "is-focused" : ""), inputsErrors.documentoNacionalMexico && 'error'].join(" ")}>
                            <label htmlFor="documentoNacionalMexico" className="bmd-label-floating">Documento Nacional Mexicano:</label>
                            <input type="text" className="form-control" id="documentoNacionalMexico" name="documentoNacionalMexico" defaultValue={inputsValue.documentoNacionalMexico} onChange={handleChange} onFocus={e => {
                                setFocusDocumentoNacionalMexico(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.documentoNacionalMexico === '') {
                                    setFocusDocumentoNacionalMexico(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.documentoNacionalMexico && (
                                <span id="ht-documentoNacionalMexico" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.documentoNacionalMexico}</span>
                            )}
                        </div> : ''
                    }
                    
                    {/* { (enableSide) ?
                        <div className={["form-group", "bmd-form-group", ((focusNumeroInterior || inputsValue.side) ? "is-focused" : ""), inputsErrors.side && 'error'].join(" ")}>
                            <label htmlFor="side" className="bmd-label-floating">Lado:</label>
                            <input type="text" className="form-control" id="side" name="side" defaultValue={inputsValue.side} onChange={handleChange} onFocus={e => {
                                setFocusNumeroInterior(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.side === '') {
                                    setFocusNumeroInterior(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.side && (
                                <span id="ht-side" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.side}</span>
                            )}
                        </div> : ''
                    }
                    
                    { (enableType) ?
                        <div className={["form-group", "bmd-form-group", ((focusNumeroInterior || inputsValue.type) ? "is-focused" : ""), inputsErrors.type && 'error'].join(" ")}>
                            <label htmlFor="type" className="bmd-label-floating">Tipo:</label>
                            <input type="text" className="form-control" id="type" name="type" defaultValue={inputsValue.type} onChange={handleChange} onFocus={e => {
                                setFocusNumeroInterior(true)
                                setInputActive(true)
                                setShowFooter(false)
                            }} onBlur={e => {
                                setShowFooter(true)
                                if (inputsValue.type === '') {
                                    setFocusNumeroInterior(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.type && (
                                <span id="ht-type" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.type}</span>
                            )}
                        </div> : ''
                    } */}
                </div>
                {(!esVigente) && (
                    <div className="err_finale  animated slideInUp">
                        <div className="center_checks">
                            <h5>Identificación no valida </h5>
                            <p>La <b>vigencia</b> de tu identificación <b>{inputsValue.vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <Link to={{
                                    pathname: "/identificacion",
                                    state: { passport: esPassport }
                                }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">CAPTURAR NUEVAMENTE</Link>
                                <button className="btn btn-secondary forcewidth100" onClick={event => terminarFlujo(event)}>TERMINAR</button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="action_buttons animate__animated">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)} disabled={sendForm}  >MIS DATOS SON CORRECTOS</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color" onClick={statusSE}>CAPTURAR NUEVAMENTE</Link>
                </div>
            </form>
            {loading && <Loader />}
            {showFooter && <Footer />}
            {showCancelScreen  && <CancelarFlujo uuidTransaccion={uuidTransaccion} dataOtorgante={dataOtorgante} estado={'cancelado'}/>}
        </>
    )
}

export default FormularioEstudiante